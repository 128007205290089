

































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class WrongQuestionAnswerMultiple extends Vue {
  public name = "WrongQuestionAnswerMultiple";

  @Prop()
  public answer!: any;

  @Prop()
  public multipleQuestion!: any;

  @Prop()
  public withMedia!: any;

  private questionOptions: Array<any> = [];

  @Watch("multipleQuestion", { immediate: true, deep: true })
  public onMultipleQuestionChange(question: any): void {
    if (question && question.correctAnswer) {
      const questionCheckBoxes = question.correctAnswer.split(";");
      const answerCheckBoxes = this.answer.answer ? this.answer.answer.split(";") : [];
      this.questionOptions = question.options.map((opt: any, index: number) => {
        if (questionCheckBoxes.some((que: any) => Number(que) === opt.position)) {
          if (answerCheckBoxes.some((ans: any) => Number(ans) === opt.position)) {
            return {
              index: index,
              correct: true,
              checked: true,
              text: opt.text,
              questionChecked: true,
            };
          }
          return {
            index: index,
            correct: false,
            checked: false,
            text: opt.text,
            questionChecked: true,
          };
        }
        if (questionCheckBoxes.some((que: any) => Number(que) !== opt.position)) {
          if (answerCheckBoxes.some((ans: any) => Number(ans) === opt.position)) {
            return {
              index: index,
              correct: false,
              checked: true,
              text: opt.text,
              questionChecked: false,
            };
          }
          return {
            index: index,
            correct: true,
            checked: false,
            text: opt.text,
            questionChecked: false,
          };
        }
      });
    }
  }

  public getMediaUrl(optionPosition: number): string {
    return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.multipleQuestion.id}/${optionPosition}/media/option?reducedSize=false`;
  }

  public setDefaultImage(event: any) {
    event.target.src = require("@/assets/lms-drivebuzz.png");
  }
}
